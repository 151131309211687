"use client"

import { StyledLink } from "@/components/styled-link"
import { VideoModalContext } from "@/contexts/video-modal"
import type { CaseStudyCard as Card } from "@/models/case-study-card"
import { useContext, type ReactNode } from "react"

export function CaseStudyCardButton(card: Readonly<Card>): ReactNode {
  const context = useContext(VideoModalContext)
  if (!context) {
    throw new Error(
      "VideoModalContext is not defined. Did you forget to wrap your component with the VideoModalProvider?"
    )
  }

  const { setOpen, setData } = context

  return (
    <StyledLink
      as="button"
      onClick={() => {
        setData(card.caseStudy)
        setOpen(true)
      }}
      title="Watch the case study"
      className="text-sm"
    >
      Watch the case study
    </StyledLink>
  )
}
